<template>
  <div>
    <h5 class="pl-1 font-weight-bold my-2">OUTSIDE EXPERIENCE</h5>
    <table class="table table-sm table-bordered small">
      <thead>
      <tr>
        <th class="align-middle text-center"  style="width: 7%">Manning</th>
        <th class="align-middle text-center">Vessel</th>
        <th class="align-middle text-center">Vessel Type</th>
        <th class="align-middle text-center">Gross Ton</th>
        <th class="align-middle text-center">Route</th>
        <th class="align-middle text-center">Rank</th>
        <th class="align-middle text-center">Embark Date</th>
        <th class="align-middle text-center">Disembark Date</th>
        <th class="align-middle text-center">No. of Days</th>
      </tr>
      </thead>
      <tbody>
        <template v-if="outsideExperience">
          <tr v-for="experience in outsideExperience">
            <td>{{experience.other_manning}}</td>
            <td>{{experience.other_vessel}}</td>
            <td>{{experience.other_vessel_type}}</td>
            <td>{{experience.gross_ton}}</td>
            <td>{{experience.other_trade_route}}</td>
            <td>{{experience.rank ? experience.rank.toUpperCase() : experience.other_rank ? experience.other_rank.toUpperCase() : '' }}</td>
            <td>{{formatEliteDate(experience.embark_date)}}</td>
            <td>{{formatEliteDate(experience.disembark_date)}}</td>
            <td>{{experience.noOfDays}}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {DateService} from "@/services/DateService";

export default {
  name: "OutsideExperience",
  async created() {
    const params={
      cms_id:this.$route.params.id
    }

    await this.getOutsideExperience(params);
  },
  methods:{
    ...mapActions(['getOutsideExperience']),
    formatEliteDate(date) {
      return DateService.dateEliteFormat(date)
    }

  },
  computed:{
    ...mapGetters(['outsideExperience'])
  }
}
</script>

<style scoped>

</style>
